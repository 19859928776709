import React from 'react';
import SectionTextMaybe from './SectionTextMaybe';
import classNames from 'classnames';

// import { FormattedMessage } from '../../util/reactIntl';

import { Heading, PrimaryButton } from '../../components';

import css from './ListingPage.module.css';

const SectionServices = ({ publicData, currencySymbol, setSelectedService, isAcuityStylist }) => {
  if (!publicData || !currencySymbol) {
    return null;
  }

  const { services = [] } = publicData;

  return services.length > 0 ? (
    <section className={css.sectionDetails}>
      <Heading as="h2" rootClassName={css.sectionHeading}>
        Services
      </Heading>
      <ul className={css.details}>
        {services.map(service => {
          const { serviceName, servicePrice, serviceDuration, serviceDescription } = service;
          return (
            <div className={css.rowWrapper} key={`${serviceName}${serviceDescription}`}>
              <li className={css.detailsRow}>
                <SectionTextMaybe
                  heading={<span className={css.serviceTextHeading}>Service</span>}
                  text={serviceName}
                  extraClass={classNames(css.noPaddingLeft, css.flex2)}
                />
                <SectionTextMaybe
                  heading={<span className={css.serviceTextHeading}>Price</span>}
                  text={`${currencySymbol} ${servicePrice}`}
                  extraClass={classNames(css.noPaddingLeft, css.flex1)}
                />
                <SectionTextMaybe
                  heading={<span className={css.serviceTextHeading}>Duration</span>}
                  text={`${serviceDuration} Hour${serviceDuration > 1 ? 's' : ''}`}
                  extraClass={classNames(css.noPaddingLeft, css.flex2)}
                />
                <SectionTextMaybe
                  heading={<span className={css.serviceTextHeading}>Description</span>}
                  text={serviceDescription}
                  extraClass={classNames(css.noPaddingLeft, css.flex2)}
                />
              </li>

              {!isAcuityStylist && (
                <PrimaryButton
                  className={css.bookServiceButton}
                  onClick={() => setSelectedService(service)}
                >
                  Book
                </PrimaryButton>
              )}
            </div>
          );
        })}
      </ul>
    </section>
  ) : null;
};

export default SectionServices;
